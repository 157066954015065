<template>
  <App_frame tab_name="3">
    <div style="text-align: left;padding: 0 40px" class="d_flex">
      <div>
        <p class="title" style="font-size: 20px">
          情报订阅
          <el-button @click="open_dialog('新增订阅')" type="primary" style="margin-left: 50px">新增订阅</el-button>
        </p>
        <p>订阅记录</p>
      </div>
      <el-divider />
      <el-table height="calc(100vh - 230px)" :data="tableData" empty-text="暂无订阅记录" style="width: 100%">
        <el-table-column type="index" label="ID" width="50" />
        <el-table-column prop="subject" label="订阅主题" min-width="180" align="center" />
        <el-table-column prop="category" label="分类类标" min-width="180" align="center">
          <template v-slot="{ row }">
            {{row.category.length ? row.category.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="common_labels" label="通用标签" min-width="150" align="center">
          <template v-slot="{ row }">
            {{row.common_labels.length ? row.common_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="org_labels" label="机构" min-width="150" align="center">
          <template v-slot="{ row }">
            {{row.org_labels.length ? row.org_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="drug_labels" label="药物" min-width="150" align="center">
          <template v-slot="{ row }">
            {{row.drug_labels.length ? row.drug_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="target_labels" label="靶点" min-width="150" align="center">
          <template v-slot="{ row }">
            {{row.target_labels.length ? row.target_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="indication_labels" label="适应症" min-width="150" align="center">
          <template v-slot="{ row }">
            {{row.indication_labels.length ? row.indication_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="websites" label="数据来源" min-width="150" align="center">
          <template v-slot="{ row }">
            {{row.websites?.length ? row.websites.map(function(item) {
            return name_website(item.website_id);
          }).join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="订阅时间" width="150" align="center">
          <template v-slot="{ row }">
            {{dayjs(row.created_at).format('YYYY-MM-DD')}}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="当前状态" width="180" align="center">
          <template v-slot="{ row }">
            {{row.status ? '订阅中' : '断订'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="160" align="center">
          <template v-slot="{ row }">
            <el-tooltip
                v-if="row.status"
                class="box-item"
                effect="dark"
                content="取消订阅"
                placement="bottom"
            >
              <el-icon class="hand-cursor" :size="16" @click="chenge_status(row.id,row.status)">
                <StarFilled />
              </el-icon>
            </el-tooltip>
            <el-tooltip
                v-else
                class="box-item"
                effect="dark"
                content="续订"
                placement="bottom"
            >
              <el-icon class="hand-cursor" :size="16" @click="chenge_status(row.id,row.status)">
                <Star />
              </el-icon>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="查看详情"
                placement="bottom"
            >
              <i class="el-icon hand-cursor" @click="goto_advisory(row.id)">
                <svg_temp name="details" width="16" height="16" />
              </i>
            </el-tooltip>
            <el-tooltip
                content="删除"
                class="box-item"
                effect="dark"
                placement="bottom"
            >
              <div style="display: inline-flex;">
                <el-popconfirm
                    title="确认删除该条记录"
                    width="300"
                    @confirm="delete_sub(row.id)"
                >
                  <template #reference>
                    <el-icon class="hand-cursor" :size="16">
                      <Delete/>
                    </el-icon>
                  </template>
                </el-popconfirm>
              </div>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="编辑"
                placement="bottom"
            >
              <el-icon class="hand-cursor" :size="16" @click="edit_sub(row,'修改订阅信息')">
                <Edit />
              </el-icon>
            </el-tooltip>
<!--            <el-button @click="chenge_status(row.id,row.status)" type="primary" link>-->
<!--              {{row.status ? '取消订阅' : '续订'}}-->
<!--            </el-button>-->
<!--            <el-button @click="goto_advisory(row.id)" type="primary" link>-->
<!--              查看详情-->
<!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination :current-page="current_page" :page-size="page_size" :page-sizes="[20, 40, 60, 80, 100]"
                       :total="dataTotal"
                       size="small"
                       align="center" layout="total, sizes, prev, pager, next, jumper"
                       @size-change="page_size_change" @current-change="current_page_change">
        </el-pagination>
      </div>
      <New_subscription v-if="dialogTableVisible" v-model:isdialog="dialogTableVisible" :label_list="submit_list" :title="dialog_title" :website_name="website_name_list" :tos="tos" :sub_id="sub_id" :subject="subject" :schedule="schedule" @send-visible="change_visibel" />
    </div>
  </App_frame>
</template>

<script setup>
import {ref, onMounted, reactive} from "vue";
import {delete_subscribe, get_subscribe, modify_subscribe, website_fuzz} from "@/api/data.js";
import {dayjs} from "element-plus";
import {useRoute} from "vue-router";
import router from "@/router/index.js";
const route = useRoute()

const tableData = ref([])
const dataTotal = ref(0)
const current_page = ref(1)
const page_size = ref(20)
const dialogTableVisible = ref(false)
const dialog_title = ref('')
const submit_list = reactive({
  category_list: [],
  website_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
})
const tos = ref([])
const sub_id = ref('')
const subject = ref('')
const schedule = ref(null)
const website_name_list = ref([])

const change_visibel = ({visible,success})=>{
  dialogTableVisible.value = visible
  if(success === true){
    ElMessage({
      message: '订阅成功',
      type: 'success',
    })
  }
  update_data()
}
const page_size_change = (val) => {
  page_size.value = val
  update_data()
}
const current_page_change = (val) => {
  current_page.value = val
  update_data()
}
const update_data = ()=>{
  get_subscribe(current_page.value, page_size.value)
      .then(res=>{
        dataTotal.value = res.data.total
        tableData.value = res.data.results
      })
}
const chenge_status = (id, type) => {
  modify_subscribe(id)
      .then(res=>{
        update_data()
        if(type){
          ElMessage({
            message: '退订成功',
            type: 'success',
          })
        } else {
          ElMessage({
            message: '续订成功',
            type: 'success',
          })
        }
      })
      .catch(()=>{
        if(type){
          ElMessage.error('退订失败')
        } else {
          ElMessage.error('续订失败')
        }
      })
}
const goto_advisory = (id) =>{
  router.push({
    name: 'advisory',
    query: { sub: id }
  })
}
const open_dialog = (title) => {
  submit_list.category_list = []
  submit_list.common_list = []
  submit_list.drug_list = []
  submit_list.target_list = []
  submit_list.indication_list = []
  submit_list.org_list = []
  submit_list.website_list = []
  sub_id.value = ''
  tos.value = []
  subject.value = ''
  schedule.value = null
  dialogTableVisible.value = true
  dialog_title.value = title
}
const delete_sub = (id) => {
  delete_subscribe(id)
      .then(res=>{
        // console.log(res)
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        update_data()
      })
}
const edit_sub = (row,title) => {
  submit_list.category_list = row.category
  submit_list.common_list = row.common_labels
  submit_list.drug_list = row.drug_labels
  submit_list.target_list = row.target_labels
  submit_list.indication_list = row.indication_labels
  submit_list.org_list = row.org_labels
  submit_list.website_list = row.websites.map(function(item) {
    return item.website_id;
  })
  sub_id.value = row.id
  tos.value = row.tos
  subject.value = row.subject
  schedule.value = row.schedule
  dialogTableVisible.value = true
  dialog_title.value = title
}
const name_website = (id) => {
  let name = ''
  if(website_name_list.value.length){
    website_name_list.value.forEach((val) => {
      if(val.website_id === id){
        console.log(val)
        name = val.website_name
      }
    })
  } else {
    name = id
  }
  return name
}

onMounted(()=>{
  update_data()
  website_fuzz('')
      .then(res => {
        website_name_list.value = res.data.result.infos
      })
})

</script>

<style scoped>
.title{
  padding: 20px 0;
  font-size: 20px
}
:deep(.el-divider--horizontal){
  margin: 8px 0;
}
.pagination{
  display: flex;
  justify-content: center;
}
.hand-cursor {
  cursor: pointer;
}
</style>